const ficaTaxRate = [
    { 'id': 1, 'name': 'Social Security', 'rate': 6.2 },
    { 'id': 2, 'name': 'Medicare', 'rate': 1.45 }
];
const federalTaxBracket = [
    //single 2022
    { 'id': 22, 'year': 2022, 'rate': 10, 'incomeMin': 1, 'incomeMax': 10275, 'filing_status': 's' },
    { 'id': 23, 'year': 2022, 'rate': 12, 'incomeMin': 10276, 'incomeMax': 41775, 'filing_status': 's' },
    { 'id': 24, 'year': 2022, 'rate': 22, 'incomeMin': 41776, 'incomeMax': 89075, 'filing_status': 's' },
    { 'id': 25, 'year': 2022, 'rate': 24, 'incomeMin': 89076, 'incomeMax': 170050, 'filing_status': 's' },
    { 'id': 26, 'year': 2022, 'rate': 32, 'incomeMin': 170051, 'incomeMax': 215950, 'filing_status': 's' },
    { 'id': 27, 'year': 2022, 'rate': 35, 'incomeMin': 215951, 'incomeMax': 539900, 'filing_status': 's' },
    { 'id': 28, 'year': 2022, 'rate': 37, 'incomeMin': 539901, 'incomeMax': 9007199254740991, 'filing_status': 's' },
    //married 2022
    { 'id': 29, 'year': 2022, 'rate': 10, 'incomeMin': 1, 'incomeMax': 20550, 'filing_status': 'm' },
    { 'id': 30, 'year': 2022, 'rate': 12, 'incomeMin': 20551, 'incomeMax': 83550, 'filing_status': 'm' },
    { 'id': 31, 'year': 2022, 'rate': 22, 'incomeMin': 83551, 'incomeMax': 178150, 'filing_status': 'm' },
    { 'id': 32, 'year': 2022, 'rate': 24, 'incomeMin': 178151, 'incomeMax': 340100, 'filing_status': 'm' },
    { 'id': 33, 'year': 2022, 'rate': 32, 'incomeMin': 340101, 'incomeMax': 431900, 'filing_status': 'm' },
    { 'id': 34, 'year': 2022, 'rate': 35, 'incomeMin': 431901, 'incomeMax': 647850, 'filing_status': 'm' },
    { 'id': 35, 'year': 2022, 'rate': 37, 'incomeMin': 647851, 'incomeMax': 9007199254740991, 'filing_status': 'm' },
    //head of household 2022
    { 'id': 36, 'year': 2022, 'rate': 10, 'incomeMin': 1, 'incomeMax': 14650, 'filing_status': 'h' },
    { 'id': 37, 'year': 2022, 'rate': 12, 'incomeMin': 14651, 'incomeMax': 55900, 'filing_status': 'h' },
    { 'id': 38, 'year': 2022, 'rate': 22, 'incomeMin': 55901, 'incomeMax': 89050, 'filing_status': 'h' },
    { 'id': 39, 'year': 2022, 'rate': 24, 'incomeMin': 89051, 'incomeMax': 170050, 'filing_status': 'h' },
    { 'id': 40, 'year': 2022, 'rate': 32, 'incomeMin': 170051, 'incomeMax': 215950, 'filing_status': 'h' },
    { 'id': 41, 'year': 2022, 'rate': 35, 'incomeMin': 215951, 'incomeMax': 539900, 'filing_status': 'h' },
    { 'id': 42, 'year': 2022, 'rate': 37, 'incomeMin': 539901, 'incomeMax': 9007199254740991, 'filing_status': 'h' }
];
const stateTaxBracket = [
    // 2022 State Taxes
    {'id': 8, 'year': 2022, 'rate': 0.0, 'state': 'AK', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 7, 'year': 2022, 'rate': 0.0, 'state': 'AK', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 2, 'year': 2022, 'rate': 2.0, 'state': 'AL', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 4, 'year': 2022, 'rate': 4.0, 'state': 'AL', 'incomeMin': 1000, 'filing_status': 'm'},
	{'id': 6, 'year': 2022, 'rate': 5.0, 'state': 'AL', 'incomeMin': 6000, 'filing_status': 'm'},
	{'id': 1, 'year': 2022, 'rate': 2.0, 'state': 'AL', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 3, 'year': 2022, 'rate': 4.0, 'state': 'AL', 'incomeMin': 500, 'filing_status': 's'},
	{'id': 5, 'year': 2022, 'rate': 5.0, 'state': 'AL', 'incomeMin': 3000, 'filing_status': 's'},
	{'id': 18, 'year': 2022, 'rate': 2.0, 'state': 'AR', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 20, 'year': 2022, 'rate': 4.0, 'state': 'AR', 'incomeMin': 4300, 'filing_status': 'm'},
	{'id': 22, 'year': 2022, 'rate': 5.5, 'state': 'AR', 'incomeMin': 8500, 'filing_status': 'm'},
	{'id': 17, 'year': 2022, 'rate': 2.0, 'state': 'AR', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 19, 'year': 2022, 'rate': 4.0, 'state': 'AR', 'incomeMin': 4300, 'filing_status': 's'},
	{'id': 21, 'year': 2022, 'rate': 5.5, 'state': 'AR', 'incomeMin': 8500, 'filing_status': 's'},
	{'id': 10, 'year': 2022, 'rate': 2.59, 'state': 'AZ', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 12, 'year': 2022, 'rate': 3.34, 'state': 'AZ', 'incomeMin': 55615, 'filing_status': 'm'},
	{'id': 14, 'year': 2022, 'rate': 4.17, 'state': 'AZ', 'incomeMin': 111229, 'filing_status': 'm'},
	{'id': 16, 'year': 2022, 'rate': 4.5, 'state': 'AZ', 'incomeMin': 333684, 'filing_status': 'm'},
	{'id': 9, 'year': 2022, 'rate': 2.59, 'state': 'AZ', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 11, 'year': 2022, 'rate': 3.34, 'state': 'AZ', 'incomeMin': 27808, 'filing_status': 's'},
	{'id': 13, 'year': 2022, 'rate': 4.17, 'state': 'AZ', 'incomeMin': 55615, 'filing_status': 's'},
	{'id': 15, 'year': 2022, 'rate': 4.5, 'state': 'AZ', 'incomeMin': 166843, 'filing_status': 's'},
	{'id': 24, 'year': 2022, 'rate': 1.0, 'state': 'CA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 26, 'year': 2022, 'rate': 2.0, 'state': 'CA', 'incomeMin': 18650, 'filing_status': 'm'},
	{'id': 28, 'year': 2022, 'rate': 4.0, 'state': 'CA', 'incomeMin': 44214, 'filing_status': 'm'},
	{'id': 30, 'year': 2022, 'rate': 6.0, 'state': 'CA', 'incomeMin': 69784, 'filing_status': 'm'},
	{'id': 32, 'year': 2022, 'rate': 8.0, 'state': 'CA', 'incomeMin': 96870, 'filing_status': 'm'},
	{'id': 34, 'year': 2022, 'rate': 9.3, 'state': 'CA', 'incomeMin': 122428, 'filing_status': 'm'},
	{'id': 36, 'year': 2022, 'rate': 10.3, 'state': 'CA', 'incomeMin': 625372, 'filing_status': 'm'},
	{'id': 38, 'year': 2022, 'rate': 11.3, 'state': 'CA', 'incomeMin': 750442, 'filing_status': 'm'},
	{'id': 40, 'year': 2022, 'rate': 12.3, 'state': 'CA', 'incomeMin': 1000000, 'filing_status': 'm'},
	{'id': 42, 'year': 2022, 'rate': 13.3, 'state': 'CA', 'incomeMin': 1250738, 'filing_status': 'm'},
	{'id': 23, 'year': 2022, 'rate': 1.0, 'state': 'CA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 25, 'year': 2022, 'rate': 2.0, 'state': 'CA', 'incomeMin': 9325, 'filing_status': 's'},
	{'id': 27, 'year': 2022, 'rate': 4.0, 'state': 'CA', 'incomeMin': 22107, 'filing_status': 's'},
	{'id': 29, 'year': 2022, 'rate': 6.0, 'state': 'CA', 'incomeMin': 34892, 'filing_status': 's'},
	{'id': 31, 'year': 2022, 'rate': 8.0, 'state': 'CA', 'incomeMin': 48435, 'filing_status': 's'},
	{'id': 33, 'year': 2022, 'rate': 9.3, 'state': 'CA', 'incomeMin': 61214, 'filing_status': 's'},
	{'id': 35, 'year': 2022, 'rate': 10.3, 'state': 'CA', 'incomeMin': 312686, 'filing_status': 's'},
	{'id': 37, 'year': 2022, 'rate': 11.3, 'state': 'CA', 'incomeMin': 375221, 'filing_status': 's'},
	{'id': 39, 'year': 2022, 'rate': 12.3, 'state': 'CA', 'incomeMin': 625369, 'filing_status': 's'},
	{'id': 41, 'year': 2022, 'rate': 13.3, 'state': 'CA', 'incomeMin': 1000000, 'filing_status': 's'},
	{'id': 44, 'year': 2022, 'rate': 4.55, 'state': 'CO', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 43, 'year': 2022, 'rate': 4.55, 'state': 'CO', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 46, 'year': 2022, 'rate': 3.0, 'state': 'CT', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 48, 'year': 2022, 'rate': 5.0, 'state': 'CT', 'incomeMin': 20000, 'filing_status': 'm'},
	{'id': 50, 'year': 2022, 'rate': 5.5, 'state': 'CT', 'incomeMin': 100000, 'filing_status': 'm'},
	{'id': 52, 'year': 2022, 'rate': 6.0, 'state': 'CT', 'incomeMin': 200000, 'filing_status': 'm'},
	{'id': 54, 'year': 2022, 'rate': 6.5, 'state': 'CT', 'incomeMin': 400000, 'filing_status': 'm'},
	{'id': 56, 'year': 2022, 'rate': 6.9, 'state': 'CT', 'incomeMin': 500000, 'filing_status': 'm'},
	{'id': 58, 'year': 2022, 'rate': 6.99, 'state': 'CT', 'incomeMin': 1000000, 'filing_status': 'm'},
	{'id': 45, 'year': 2022, 'rate': 3.0, 'state': 'CT', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 47, 'year': 2022, 'rate': 5.0, 'state': 'CT', 'incomeMin': 10000, 'filing_status': 's'},
	{'id': 49, 'year': 2022, 'rate': 5.5, 'state': 'CT', 'incomeMin': 50000, 'filing_status': 's'},
	{'id': 51, 'year': 2022, 'rate': 6.0, 'state': 'CT', 'incomeMin': 100000, 'filing_status': 's'},
	{'id': 53, 'year': 2022, 'rate': 6.5, 'state': 'CT', 'incomeMin': 200000, 'filing_status': 's'},
	{'id': 55, 'year': 2022, 'rate': 6.9, 'state': 'CT', 'incomeMin': 250000, 'filing_status': 's'},
	{'id': 57, 'year': 2022, 'rate': 6.99, 'state': 'CT', 'incomeMin': 500000, 'filing_status': 's'},
	{'id': 386, 'year': 2022, 'rate': 4.0, 'state': 'DC', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 388, 'year': 2022, 'rate': 6.0, 'state': 'DC', 'incomeMin': 10000, 'filing_status': 'm'},
	{'id': 390, 'year': 2022, 'rate': 6.5, 'state': 'DC', 'incomeMin': 40000, 'filing_status': 'm'},
	{'id': 392, 'year': 2022, 'rate': 8.5, 'state': 'DC', 'incomeMin': 60000, 'filing_status': 'm'},
	{'id': 394, 'year': 2022, 'rate': 9.25, 'state': 'DC', 'incomeMin': 250000, 'filing_status': 'm'},
	{'id': 396, 'year': 2022, 'rate': 9.75, 'state': 'DC', 'incomeMin': 500000, 'filing_status': 'm'},
	{'id': 398, 'year': 2022, 'rate': 10.75, 'state': 'DC', 'incomeMin': 1000000, 'filing_status': 'm'},
	{'id': 385, 'year': 2022, 'rate': 4.0, 'state': 'DC', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 387, 'year': 2022, 'rate': 6.0, 'state': 'DC', 'incomeMin': 10000, 'filing_status': 's'},
	{'id': 389, 'year': 2022, 'rate': 6.5, 'state': 'DC', 'incomeMin': 40000, 'filing_status': 's'},
	{'id': 391, 'year': 2022, 'rate': 8.5, 'state': 'DC', 'incomeMin': 60000, 'filing_status': 's'},
	{'id': 393, 'year': 2022, 'rate': 9.25, 'state': 'DC', 'incomeMin': 250000, 'filing_status': 's'},
	{'id': 395, 'year': 2022, 'rate': 9.75, 'state': 'DC', 'incomeMin': 500000, 'filing_status': 's'},
	{'id': 397, 'year': 2022, 'rate': 10.75, 'state': 'DC', 'incomeMin': 1000000, 'filing_status': 's'},
	{'id': 60, 'year': 2022, 'rate': 2.2, 'state': 'DE', 'incomeMin': 2000, 'filing_status': 'm'},
	{'id': 62, 'year': 2022, 'rate': 3.9, 'state': 'DE', 'incomeMin': 5000, 'filing_status': 'm'},
	{'id': 64, 'year': 2022, 'rate': 4.8, 'state': 'DE', 'incomeMin': 10000, 'filing_status': 'm'},
	{'id': 66, 'year': 2022, 'rate': 5.2, 'state': 'DE', 'incomeMin': 20000, 'filing_status': 'm'},
	{'id': 68, 'year': 2022, 'rate': 5.55, 'state': 'DE', 'incomeMin': 25000, 'filing_status': 'm'},
	{'id': 70, 'year': 2022, 'rate': 6.6, 'state': 'DE', 'incomeMin': 60000, 'filing_status': 'm'},
	{'id': 59, 'year': 2022, 'rate': 2.2, 'state': 'DE', 'incomeMin': 2000, 'filing_status': 's'},
	{'id': 61, 'year': 2022, 'rate': 3.9, 'state': 'DE', 'incomeMin': 5000, 'filing_status': 's'},
	{'id': 63, 'year': 2022, 'rate': 4.8, 'state': 'DE', 'incomeMin': 10000, 'filing_status': 's'},
	{'id': 65, 'year': 2022, 'rate': 5.2, 'state': 'DE', 'incomeMin': 20000, 'filing_status': 's'},
	{'id': 67, 'year': 2022, 'rate': 5.55, 'state': 'DE', 'incomeMin': 25000, 'filing_status': 's'},
	{'id': 69, 'year': 2022, 'rate': 6.6, 'state': 'DE', 'incomeMin': 60000, 'filing_status': 's'},
	{'id': 72, 'year': 2022, 'rate': 0.0, 'state': 'FL', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 71, 'year': 2022, 'rate': 0.0, 'state': 'FL', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 74, 'year': 2022, 'rate': 1.0, 'state': 'GA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 76, 'year': 2022, 'rate': 2.0, 'state': 'GA', 'incomeMin': 1000, 'filing_status': 'm'},
	{'id': 78, 'year': 2022, 'rate': 3.0, 'state': 'GA', 'incomeMin': 3000, 'filing_status': 'm'},
	{'id': 80, 'year': 2022, 'rate': 4.0, 'state': 'GA', 'incomeMin': 5000, 'filing_status': 'm'},
	{'id': 82, 'year': 2022, 'rate': 5.0, 'state': 'GA', 'incomeMin': 7000, 'filing_status': 'm'},
	{'id': 84, 'year': 2022, 'rate': 5.75, 'state': 'GA', 'incomeMin': 10000, 'filing_status': 'm'},
	{'id': 73, 'year': 2022, 'rate': 1.0, 'state': 'GA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 75, 'year': 2022, 'rate': 2.0, 'state': 'GA', 'incomeMin': 750, 'filing_status': 's'},
	{'id': 77, 'year': 2022, 'rate': 3.0, 'state': 'GA', 'incomeMin': 2250, 'filing_status': 's'},
	{'id': 79, 'year': 2022, 'rate': 4.0, 'state': 'GA', 'incomeMin': 3750, 'filing_status': 's'},
	{'id': 81, 'year': 2022, 'rate': 5.0, 'state': 'GA', 'incomeMin': 5250, 'filing_status': 's'},
	{'id': 83, 'year': 2022, 'rate': 5.75, 'state': 'GA', 'incomeMin': 7000, 'filing_status': 's'},
	{'id': 86, 'year': 2022, 'rate': 1.4, 'state': 'HI', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 88, 'year': 2022, 'rate': 3.2, 'state': 'HI', 'incomeMin': 4800, 'filing_status': 'm'},
	{'id': 90, 'year': 2022, 'rate': 5.5, 'state': 'HI', 'incomeMin': 9600, 'filing_status': 'm'},
	{'id': 92, 'year': 2022, 'rate': 6.4, 'state': 'HI', 'incomeMin': 19200, 'filing_status': 'm'},
	{'id': 94, 'year': 2022, 'rate': 6.8, 'state': 'HI', 'incomeMin': 28800, 'filing_status': 'm'},
	{'id': 96, 'year': 2022, 'rate': 7.2, 'state': 'HI', 'incomeMin': 38400, 'filing_status': 'm'},
	{'id': 98, 'year': 2022, 'rate': 7.6, 'state': 'HI', 'incomeMin': 48000, 'filing_status': 'm'},
	{'id': 100, 'year': 2022, 'rate': 7.9, 'state': 'HI', 'incomeMin': 72000, 'filing_status': 'm'},
	{'id': 102, 'year': 2022, 'rate': 8.25, 'state': 'HI', 'incomeMin': 96000, 'filing_status': 'm'},
	{'id': 104, 'year': 2022, 'rate': 9.0, 'state': 'HI', 'incomeMin': 300000, 'filing_status': 'm'},
	{'id': 106, 'year': 2022, 'rate': 10.0, 'state': 'HI', 'incomeMin': 350000, 'filing_status': 'm'},
	{'id': 108, 'year': 2022, 'rate': 11.0, 'state': 'HI', 'incomeMin': 400000, 'filing_status': 'm'},
	{'id': 85, 'year': 2022, 'rate': 1.4, 'state': 'HI', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 87, 'year': 2022, 'rate': 3.2, 'state': 'HI', 'incomeMin': 2400, 'filing_status': 's'},
	{'id': 89, 'year': 2022, 'rate': 5.5, 'state': 'HI', 'incomeMin': 4800, 'filing_status': 's'},
	{'id': 91, 'year': 2022, 'rate': 6.4, 'state': 'HI', 'incomeMin': 9600, 'filing_status': 's'},
	{'id': 93, 'year': 2022, 'rate': 6.8, 'state': 'HI', 'incomeMin': 14400, 'filing_status': 's'},
	{'id': 95, 'year': 2022, 'rate': 7.2, 'state': 'HI', 'incomeMin': 19200, 'filing_status': 's'},
	{'id': 97, 'year': 2022, 'rate': 7.6, 'state': 'HI', 'incomeMin': 24000, 'filing_status': 's'},
	{'id': 99, 'year': 2022, 'rate': 7.9, 'state': 'HI', 'incomeMin': 36000, 'filing_status': 's'},
	{'id': 101, 'year': 2022, 'rate': 8.25, 'state': 'HI', 'incomeMin': 48000, 'filing_status': 's'},
	{'id': 103, 'year': 2022, 'rate': 9.0, 'state': 'HI', 'incomeMin': 150000, 'filing_status': 's'},
	{'id': 105, 'year': 2022, 'rate': 10.0, 'state': 'HI', 'incomeMin': 175000, 'filing_status': 's'},
	{'id': 107, 'year': 2022, 'rate': 11.0, 'state': 'HI', 'incomeMin': 200000, 'filing_status': 's'},
	{'id': 122, 'year': 2022, 'rate': 0.33, 'state': 'IA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 124, 'year': 2022, 'rate': 0.67, 'state': 'IA', 'incomeMin': 1743, 'filing_status': 'm'},
	{'id': 126, 'year': 2022, 'rate': 2.25, 'state': 'IA', 'incomeMin': 3486, 'filing_status': 'm'},
	{'id': 128, 'year': 2022, 'rate': 4.14, 'state': 'IA', 'incomeMin': 6972, 'filing_status': 'm'},
	{'id': 130, 'year': 2022, 'rate': 5.63, 'state': 'IA', 'incomeMin': 15687, 'filing_status': 'm'},
	{'id': 132, 'year': 2022, 'rate': 5.96, 'state': 'IA', 'incomeMin': 26145, 'filing_status': 'm'},
	{'id': 134, 'year': 2022, 'rate': 6.25, 'state': 'IA', 'incomeMin': 34860, 'filing_status': 'm'},
	{'id': 136, 'year': 2022, 'rate': 7.44, 'state': 'IA', 'incomeMin': 52290, 'filing_status': 'm'},
	{'id': 138, 'year': 2022, 'rate': 8.53, 'state': 'IA', 'incomeMin': 78435, 'filing_status': 'm'},
	{'id': 121, 'year': 2022, 'rate': 0.33, 'state': 'IA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 123, 'year': 2022, 'rate': 0.67, 'state': 'IA', 'incomeMin': 1743, 'filing_status': 's'},
	{'id': 125, 'year': 2022, 'rate': 2.25, 'state': 'IA', 'incomeMin': 3486, 'filing_status': 's'},
	{'id': 127, 'year': 2022, 'rate': 4.14, 'state': 'IA', 'incomeMin': 6972, 'filing_status': 's'},
	{'id': 129, 'year': 2022, 'rate': 5.63, 'state': 'IA', 'incomeMin': 15687, 'filing_status': 's'},
	{'id': 131, 'year': 2022, 'rate': 5.96, 'state': 'IA', 'incomeMin': 26145, 'filing_status': 's'},
	{'id': 133, 'year': 2022, 'rate': 6.25, 'state': 'IA', 'incomeMin': 34860, 'filing_status': 's'},
	{'id': 135, 'year': 2022, 'rate': 7.44, 'state': 'IA', 'incomeMin': 52290, 'filing_status': 's'},
	{'id': 137, 'year': 2022, 'rate': 8.53, 'state': 'IA', 'incomeMin': 78435, 'filing_status': 's'},
	{'id': 110, 'year': 2022, 'rate': 1.0, 'state': 'ID', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 112, 'year': 2022, 'rate': 3.0, 'state': 'ID', 'incomeMin': 3176, 'filing_status': 'm'},
	{'id': 114, 'year': 2022, 'rate': 4.5, 'state': 'ID', 'incomeMin': 9526, 'filing_status': 'm'},
	{'id': 116, 'year': 2022, 'rate': 6.0, 'state': 'ID', 'incomeMin': 15878, 'filing_status': 'm'},
	{'id': 109, 'year': 2022, 'rate': 1.0, 'state': 'ID', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 111, 'year': 2022, 'rate': 3.0, 'state': 'ID', 'incomeMin': 1588, 'filing_status': 's'},
	{'id': 113, 'year': 2022, 'rate': 4.5, 'state': 'ID', 'incomeMin': 4763, 'filing_status': 's'},
	{'id': 115, 'year': 2022, 'rate': 6.0, 'state': 'ID', 'incomeMin': 7939, 'filing_status': 's'},
	{'id': 118, 'year': 2022, 'rate': 4.95, 'state': 'IL', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 117, 'year': 2022, 'rate': 4.95, 'state': 'IL', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 120, 'year': 2022, 'rate': 3.23, 'state': 'IN', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 119, 'year': 2022, 'rate': 3.23, 'state': 'IN', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 140, 'year': 2022, 'rate': 3.1, 'state': 'KS', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 142, 'year': 2022, 'rate': 5.25, 'state': 'KS', 'incomeMin': 30000, 'filing_status': 'm'},
	{'id': 144, 'year': 2022, 'rate': 5.7, 'state': 'KS', 'incomeMin': 60000, 'filing_status': 'm'},
	{'id': 139, 'year': 2022, 'rate': 3.1, 'state': 'KS', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 141, 'year': 2022, 'rate': 5.25, 'state': 'KS', 'incomeMin': 15000, 'filing_status': 's'},
	{'id': 143, 'year': 2022, 'rate': 5.7, 'state': 'KS', 'incomeMin': 30000, 'filing_status': 's'},
	{'id': 146, 'year': 2022, 'rate': 5.0, 'state': 'KY', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 145, 'year': 2022, 'rate': 5.0, 'state': 'KY', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 148, 'year': 2022, 'rate': 1.85, 'state': 'LA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 150, 'year': 2022, 'rate': 3.5, 'state': 'LA', 'incomeMin': 25000, 'filing_status': 'm'},
	{'id': 152, 'year': 2022, 'rate': 4.25, 'state': 'LA', 'incomeMin': 100000, 'filing_status': 'm'},
	{'id': 147, 'year': 2022, 'rate': 1.85, 'state': 'LA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 149, 'year': 2022, 'rate': 3.5, 'state': 'LA', 'incomeMin': 12500, 'filing_status': 's'},
	{'id': 151, 'year': 2022, 'rate': 4.25, 'state': 'LA', 'incomeMin': 50000, 'filing_status': 's'},
	{'id': 176, 'year': 2022, 'rate': 5.0, 'state': 'MA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 175, 'year': 2022, 'rate': 5.0, 'state': 'MA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 160, 'year': 2022, 'rate': 2.0, 'state': 'MD', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 162, 'year': 2022, 'rate': 3.0, 'state': 'MD', 'incomeMin': 1000, 'filing_status': 'm'},
	{'id': 164, 'year': 2022, 'rate': 4.0, 'state': 'MD', 'incomeMin': 2000, 'filing_status': 'm'},
	{'id': 166, 'year': 2022, 'rate': 4.75, 'state': 'MD', 'incomeMin': 3000, 'filing_status': 'm'},
	{'id': 168, 'year': 2022, 'rate': 5.0, 'state': 'MD', 'incomeMin': 150000, 'filing_status': 'm'},
	{'id': 170, 'year': 2022, 'rate': 5.25, 'state': 'MD', 'incomeMin': 175000, 'filing_status': 'm'},
	{'id': 172, 'year': 2022, 'rate': 5.5, 'state': 'MD', 'incomeMin': 225000, 'filing_status': 'm'},
	{'id': 174, 'year': 2022, 'rate': 5.75, 'state': 'MD', 'incomeMin': 300000, 'filing_status': 'm'},
	{'id': 159, 'year': 2022, 'rate': 2.0, 'state': 'MD', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 161, 'year': 2022, 'rate': 3.0, 'state': 'MD', 'incomeMin': 1000, 'filing_status': 's'},
	{'id': 163, 'year': 2022, 'rate': 4.0, 'state': 'MD', 'incomeMin': 2000, 'filing_status': 's'},
	{'id': 165, 'year': 2022, 'rate': 4.75, 'state': 'MD', 'incomeMin': 3000, 'filing_status': 's'},
	{'id': 167, 'year': 2022, 'rate': 5.0, 'state': 'MD', 'incomeMin': 100000, 'filing_status': 's'},
	{'id': 169, 'year': 2022, 'rate': 5.25, 'state': 'MD', 'incomeMin': 125000, 'filing_status': 's'},
	{'id': 171, 'year': 2022, 'rate': 5.5, 'state': 'MD', 'incomeMin': 150000, 'filing_status': 's'},
	{'id': 173, 'year': 2022, 'rate': 5.75, 'state': 'MD', 'incomeMin': 250000, 'filing_status': 's'},
	{'id': 154, 'year': 2022, 'rate': 5.8, 'state': 'ME', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 156, 'year': 2022, 'rate': 6.75, 'state': 'ME', 'incomeMin': 46000, 'filing_status': 'm'},
	{'id': 158, 'year': 2022, 'rate': 7.15, 'state': 'ME', 'incomeMin': 108900, 'filing_status': 'm'},
	{'id': 153, 'year': 2022, 'rate': 5.8, 'state': 'ME', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 155, 'year': 2022, 'rate': 6.75, 'state': 'ME', 'incomeMin': 23000, 'filing_status': 's'},
	{'id': 157, 'year': 2022, 'rate': 7.15, 'state': 'ME', 'incomeMin': 54450, 'filing_status': 's'},
	{'id': 180, 'year': 2022, 'rate': 5.35, 'state': 'MN', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 182, 'year': 2022, 'rate': 6.8, 'state': 'MN', 'incomeMin': 41050, 'filing_status': 'm'},
	{'id': 184, 'year': 2022, 'rate': 7.85, 'state': 'MN', 'incomeMin': 163060, 'filing_status': 'm'},
	{'id': 186, 'year': 2022, 'rate': 9.85, 'state': 'MN', 'incomeMin': 284810, 'filing_status': 'm'},
	{'id': 179, 'year': 2022, 'rate': 5.35, 'state': 'MN', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 181, 'year': 2022, 'rate': 6.8, 'state': 'MN', 'incomeMin': 28080, 'filing_status': 's'},
	{'id': 183, 'year': 2022, 'rate': 7.85, 'state': 'MN', 'incomeMin': 92230, 'filing_status': 's'},
	{'id': 185, 'year': 2022, 'rate': 9.85, 'state': 'MN', 'incomeMin': 171220, 'filing_status': 's'},
	{'id': 192, 'year': 2022, 'rate': 1.5, 'state': 'MO', 'incomeMin': 108, 'filing_status': 'm'},
	{'id': 194, 'year': 2022, 'rate': 2.0, 'state': 'MO', 'incomeMin': 1088, 'filing_status': 'm'},
	{'id': 196, 'year': 2022, 'rate': 2.5, 'state': 'MO', 'incomeMin': 2176, 'filing_status': 'm'},
	{'id': 198, 'year': 2022, 'rate': 3.0, 'state': 'MO', 'incomeMin': 3264, 'filing_status': 'm'},
	{'id': 200, 'year': 2022, 'rate': 3.5, 'state': 'MO', 'incomeMin': 4352, 'filing_status': 'm'},
	{'id': 202, 'year': 2022, 'rate': 4.0, 'state': 'MO', 'incomeMin': 5440, 'filing_status': 'm'},
	{'id': 204, 'year': 2022, 'rate': 4.5, 'state': 'MO', 'incomeMin': 6528, 'filing_status': 'm'},
	{'id': 206, 'year': 2022, 'rate': 5.0, 'state': 'MO', 'incomeMin': 7616, 'filing_status': 'm'},
	{'id': 208, 'year': 2022, 'rate': 5.4, 'state': 'MO', 'incomeMin': 8704, 'filing_status': 'm'},
	{'id': 191, 'year': 2022, 'rate': 1.5, 'state': 'MO', 'incomeMin': 108, 'filing_status': 's'},
	{'id': 193, 'year': 2022, 'rate': 2.0, 'state': 'MO', 'incomeMin': 1088, 'filing_status': 's'},
	{'id': 195, 'year': 2022, 'rate': 2.5, 'state': 'MO', 'incomeMin': 2176, 'filing_status': 's'},
	{'id': 197, 'year': 2022, 'rate': 3.0, 'state': 'MO', 'incomeMin': 3264, 'filing_status': 's'},
	{'id': 199, 'year': 2022, 'rate': 3.5, 'state': 'MO', 'incomeMin': 4352, 'filing_status': 's'},
	{'id': 201, 'year': 2022, 'rate': 4.0, 'state': 'MO', 'incomeMin': 5440, 'filing_status': 's'},
	{'id': 203, 'year': 2022, 'rate': 4.5, 'state': 'MO', 'incomeMin': 6528, 'filing_status': 's'},
	{'id': 205, 'year': 2022, 'rate': 5.0, 'state': 'MO', 'incomeMin': 7616, 'filing_status': 's'},
	{'id': 207, 'year': 2022, 'rate': 5.4, 'state': 'MO', 'incomeMin': 8704, 'filing_status': 's'},
	{'id': 188, 'year': 2022, 'rate': 4.0, 'state': 'MS', 'incomeMin': 5000, 'filing_status': 'm'},
	{'id': 190, 'year': 2022, 'rate': 5.0, 'state': 'MS', 'incomeMin': 10000, 'filing_status': 'm'},
	{'id': 187, 'year': 2022, 'rate': 4.0, 'state': 'MS', 'incomeMin': 5000, 'filing_status': 's'},
	{'id': 189, 'year': 2022, 'rate': 5.0, 'state': 'MS', 'incomeMin': 10000, 'filing_status': 's'},
	{'id': 210, 'year': 2022, 'rate': 1.0, 'state': 'MT', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 212, 'year': 2022, 'rate': 2.0, 'state': 'MT', 'incomeMin': 3100, 'filing_status': 'm'},
	{'id': 214, 'year': 2022, 'rate': 3.0, 'state': 'MT', 'incomeMin': 5500, 'filing_status': 'm'},
	{'id': 216, 'year': 2022, 'rate': 4.0, 'state': 'MT', 'incomeMin': 8400, 'filing_status': 'm'},
	{'id': 218, 'year': 2022, 'rate': 5.0, 'state': 'MT', 'incomeMin': 11400, 'filing_status': 'm'},
	{'id': 220, 'year': 2022, 'rate': 6.0, 'state': 'MT', 'incomeMin': 14600, 'filing_status': 'm'},
	{'id': 222, 'year': 2022, 'rate': 6.75, 'state': 'MT', 'incomeMin': 18800, 'filing_status': 'm'},
	{'id': 209, 'year': 2022, 'rate': 1.0, 'state': 'MT', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 211, 'year': 2022, 'rate': 2.0, 'state': 'MT', 'incomeMin': 3100, 'filing_status': 's'},
	{'id': 213, 'year': 2022, 'rate': 3.0, 'state': 'MT', 'incomeMin': 5500, 'filing_status': 's'},
	{'id': 215, 'year': 2022, 'rate': 4.0, 'state': 'MT', 'incomeMin': 8400, 'filing_status': 's'},
	{'id': 217, 'year': 2022, 'rate': 5.0, 'state': 'MT', 'incomeMin': 11400, 'filing_status': 's'},
	{'id': 219, 'year': 2022, 'rate': 6.0, 'state': 'MT', 'incomeMin': 14600, 'filing_status': 's'},
	{'id': 221, 'year': 2022, 'rate': 6.75, 'state': 'MT', 'incomeMin': 18800, 'filing_status': 's'},
	{'id': 280, 'year': 2022, 'rate': 4.99, 'state': 'NC', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 279, 'year': 2022, 'rate': 4.99, 'state': 'NC', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 282, 'year': 2022, 'rate': 1.1, 'state': 'ND', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 284, 'year': 2022, 'rate': 2.04, 'state': 'ND', 'incomeMin': 67700, 'filing_status': 'm'},
	{'id': 286, 'year': 2022, 'rate': 2.27, 'state': 'ND', 'incomeMin': 163550, 'filing_status': 'm'},
	{'id': 288, 'year': 2022, 'rate': 2.64, 'state': 'ND', 'incomeMin': 249150, 'filing_status': 'm'},
	{'id': 290, 'year': 2022, 'rate': 2.9, 'state': 'ND', 'incomeMin': 445000, 'filing_status': 'm'},
	{'id': 281, 'year': 2022, 'rate': 1.1, 'state': 'ND', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 283, 'year': 2022, 'rate': 2.04, 'state': 'ND', 'incomeMin': 40525, 'filing_status': 's'},
	{'id': 285, 'year': 2022, 'rate': 2.27, 'state': 'ND', 'incomeMin': 98100, 'filing_status': 's'},
	{'id': 287, 'year': 2022, 'rate': 2.64, 'state': 'ND', 'incomeMin': 204675, 'filing_status': 's'},
	{'id': 289, 'year': 2022, 'rate': 2.9, 'state': 'ND', 'incomeMin': 445000, 'filing_status': 's'},
	{'id': 224, 'year': 2022, 'rate': 2.46, 'state': 'NE', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 226, 'year': 2022, 'rate': 3.51, 'state': 'NE', 'incomeMin': 6860, 'filing_status': 'm'},
	{'id': 228, 'year': 2022, 'rate': 5.01, 'state': 'NE', 'incomeMin': 41190, 'filing_status': 'm'},
	{'id': 230, 'year': 2022, 'rate': 6.84, 'state': 'NE', 'incomeMin': 66360, 'filing_status': 'm'},
	{'id': 223, 'year': 2022, 'rate': 2.46, 'state': 'NE', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 225, 'year': 2022, 'rate': 3.51, 'state': 'NE', 'incomeMin': 3440, 'filing_status': 's'},
	{'id': 227, 'year': 2022, 'rate': 5.01, 'state': 'NE', 'incomeMin': 20590, 'filing_status': 's'},
	{'id': 229, 'year': 2022, 'rate': 6.84, 'state': 'NE', 'incomeMin': 33180, 'filing_status': 's'},
	{'id': 234, 'year': 2022, 'rate': 5.0, 'state': 'NH', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 233, 'year': 2022, 'rate': 5.0, 'state': 'NH', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 178, 'year': 2022, 'rate': 4.25, 'state': 'NI', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 177, 'year': 2022, 'rate': 4.25, 'state': 'NI', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 236, 'year': 2022, 'rate': 1.4, 'state': 'NJ', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 238, 'year': 2022, 'rate': 1.75, 'state': 'NJ', 'incomeMin': 20000, 'filing_status': 'm'},
	{'id': 240, 'year': 2022, 'rate': 2.45, 'state': 'NJ', 'incomeMin': 50000, 'filing_status': 'm'},
	{'id': 242, 'year': 2022, 'rate': 3.5, 'state': 'NJ', 'incomeMin': 70000, 'filing_status': 'm'},
	{'id': 244, 'year': 2022, 'rate': 5.525, 'state': 'NJ', 'incomeMin': 80000, 'filing_status': 'm'},
	{'id': 246, 'year': 2022, 'rate': 6.37, 'state': 'NJ', 'incomeMin': 150000, 'filing_status': 'm'},
	{'id': 248, 'year': 2022, 'rate': 8.97, 'state': 'NJ', 'incomeMin': 500000, 'filing_status': 'm'},
	{'id': 250, 'year': 2022, 'rate': 10.75, 'state': 'NJ', 'incomeMin': 1000000, 'filing_status': 'm'},
	{'id': 235, 'year': 2022, 'rate': 1.4, 'state': 'NJ', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 237, 'year': 2022, 'rate': 1.75, 'state': 'NJ', 'incomeMin': 20000, 'filing_status': 's'},
	{'id': 239, 'year': 2022, 'rate': 3.5, 'state': 'NJ', 'incomeMin': 35000, 'filing_status': 's'},
	{'id': 241, 'year': 2022, 'rate': 5.525, 'state': 'NJ', 'incomeMin': 40000, 'filing_status': 's'},
	{'id': 243, 'year': 2022, 'rate': 6.37, 'state': 'NJ', 'incomeMin': 75000, 'filing_status': 's'},
	{'id': 245, 'year': 2022, 'rate': 8.97, 'state': 'NJ', 'incomeMin': 500000, 'filing_status': 's'},
	{'id': 247, 'year': 2022, 'rate': 10.75, 'state': 'NJ', 'incomeMin': 1000000, 'filing_status': 's'},
	{'id': 249, 'year': 2022, 'rate': 10.75, 'state': 'NJ', 'incomeMin': 1000000, 'filing_status': 's'},
	{'id': 252, 'year': 2022, 'rate': 1.7, 'state': 'NM', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 254, 'year': 2022, 'rate': 3.2, 'state': 'NM', 'incomeMin': 8000, 'filing_status': 'm'},
	{'id': 256, 'year': 2022, 'rate': 4.7, 'state': 'NM', 'incomeMin': 16000, 'filing_status': 'm'},
	{'id': 258, 'year': 2022, 'rate': 4.9, 'state': 'NM', 'incomeMin': 24000, 'filing_status': 'm'},
	{'id': 260, 'year': 2022, 'rate': 5.9, 'state': 'NM', 'incomeMin': 315000, 'filing_status': 'm'},
	{'id': 251, 'year': 2022, 'rate': 1.7, 'state': 'NM', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 253, 'year': 2022, 'rate': 3.2, 'state': 'NM', 'incomeMin': 5500, 'filing_status': 's'},
	{'id': 255, 'year': 2022, 'rate': 4.7, 'state': 'NM', 'incomeMin': 11000, 'filing_status': 's'},
	{'id': 257, 'year': 2022, 'rate': 4.9, 'state': 'NM', 'incomeMin': 16000, 'filing_status': 's'},
	{'id': 259, 'year': 2022, 'rate': 5.9, 'state': 'NM', 'incomeMin': 210000, 'filing_status': 's'},
	{'id': 232, 'year': 2022, 'rate': 0.0, 'state': 'NV', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 231, 'year': 2022, 'rate': 0.0, 'state': 'NV', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 262, 'year': 2022, 'rate': 4.0, 'state': 'NY', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 264, 'year': 2022, 'rate': 4.5, 'state': 'NY', 'incomeMin': 17150, 'filing_status': 'm'},
	{'id': 266, 'year': 2022, 'rate': 5.25, 'state': 'NY', 'incomeMin': 23600, 'filing_status': 'm'},
	{'id': 268, 'year': 2022, 'rate': 5.85, 'state': 'NY', 'incomeMin': 27900, 'filing_status': 'm'},
	{'id': 270, 'year': 2022, 'rate': 6.25, 'state': 'NY', 'incomeMin': 161550, 'filing_status': 'm'},
	{'id': 272, 'year': 2022, 'rate': 6.85, 'state': 'NY', 'incomeMin': 323200, 'filing_status': 'm'},
	{'id': 274, 'year': 2022, 'rate': 9.65, 'state': 'NY', 'incomeMin': 2155350, 'filing_status': 'm'},
	{'id': 276, 'year': 2022, 'rate': 10.3, 'state': 'NY', 'incomeMin': 5000000, 'filing_status': 'm'},
	{'id': 278, 'year': 2022, 'rate': 10.9, 'state': 'NY', 'incomeMin': 25000000, 'filing_status': 'm'},
	{'id': 261, 'year': 2022, 'rate': 4.0, 'state': 'NY', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 263, 'year': 2022, 'rate': 4.5, 'state': 'NY', 'incomeMin': 8500, 'filing_status': 's'},
	{'id': 265, 'year': 2022, 'rate': 5.25, 'state': 'NY', 'incomeMin': 11700, 'filing_status': 's'},
	{'id': 267, 'year': 2022, 'rate': 5.85, 'state': 'NY', 'incomeMin': 13900, 'filing_status': 's'},
	{'id': 269, 'year': 2022, 'rate': 6.25, 'state': 'NY', 'incomeMin': 80650, 'filing_status': 's'},
	{'id': 271, 'year': 2022, 'rate': 6.85, 'state': 'NY', 'incomeMin': 215400, 'filing_status': 's'},
	{'id': 273, 'year': 2022, 'rate': 9.65, 'state': 'NY', 'incomeMin': 1077550, 'filing_status': 's'},
	{'id': 275, 'year': 2022, 'rate': 10.3, 'state': 'NY', 'incomeMin': 5000000, 'filing_status': 's'},
	{'id': 277, 'year': 2022, 'rate': 10.9, 'state': 'NY', 'incomeMin': 25000000, 'filing_status': 's'},
	{'id': 292, 'year': 2022, 'rate': 2.765, 'state': 'OH', 'incomeMin': 25000, 'filing_status': 'm'},
	{'id': 294, 'year': 2022, 'rate': 3.226, 'state': 'OH', 'incomeMin': 44250, 'filing_status': 'm'},
	{'id': 296, 'year': 2022, 'rate': 3.688, 'state': 'OH', 'incomeMin': 88450, 'filing_status': 'm'},
	{'id': 298, 'year': 2022, 'rate': 3.99, 'state': 'OH', 'incomeMin': 110650, 'filing_status': 'm'},
	{'id': 291, 'year': 2022, 'rate': 2.765, 'state': 'OH', 'incomeMin': 25000, 'filing_status': 's'},
	{'id': 293, 'year': 2022, 'rate': 3.226, 'state': 'OH', 'incomeMin': 44250, 'filing_status': 's'},
	{'id': 295, 'year': 2022, 'rate': 3.688, 'state': 'OH', 'incomeMin': 88450, 'filing_status': 's'},
	{'id': 297, 'year': 2022, 'rate': 3.99, 'state': 'OH', 'incomeMin': 110650, 'filing_status': 's'},
	{'id': 300, 'year': 2022, 'rate': 0.25, 'state': 'OK', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 302, 'year': 2022, 'rate': 0.75, 'state': 'OK', 'incomeMin': 2000, 'filing_status': 'm'},
	{'id': 304, 'year': 2022, 'rate': 1.75, 'state': 'OK', 'incomeMin': 5000, 'filing_status': 'm'},
	{'id': 306, 'year': 2022, 'rate': 2.75, 'state': 'OK', 'incomeMin': 7500, 'filing_status': 'm'},
	{'id': 308, 'year': 2022, 'rate': 3.75, 'state': 'OK', 'incomeMin': 9800, 'filing_status': 'm'},
	{'id': 310, 'year': 2022, 'rate': 4.75, 'state': 'OK', 'incomeMin': 12200, 'filing_status': 'm'},
	{'id': 299, 'year': 2022, 'rate': 0.25, 'state': 'OK', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 301, 'year': 2022, 'rate': 0.75, 'state': 'OK', 'incomeMin': 1000, 'filing_status': 's'},
	{'id': 303, 'year': 2022, 'rate': 1.75, 'state': 'OK', 'incomeMin': 2500, 'filing_status': 's'},
	{'id': 305, 'year': 2022, 'rate': 2.75, 'state': 'OK', 'incomeMin': 3750, 'filing_status': 's'},
	{'id': 307, 'year': 2022, 'rate': 3.75, 'state': 'OK', 'incomeMin': 4900, 'filing_status': 's'},
	{'id': 309, 'year': 2022, 'rate': 4.75, 'state': 'OK', 'incomeMin': 7200, 'filing_status': 's'},
	{'id': 312, 'year': 2022, 'rate': 4.75, 'state': 'OR', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 314, 'year': 2022, 'rate': 6.75, 'state': 'OR', 'incomeMin': 7300, 'filing_status': 'm'},
	{'id': 316, 'year': 2022, 'rate': 8.75, 'state': 'OR', 'incomeMin': 50000, 'filing_status': 'm'},
	{'id': 318, 'year': 2022, 'rate': 9.9, 'state': 'OR', 'incomeMin': 250000, 'filing_status': 'm'},
	{'id': 311, 'year': 2022, 'rate': 4.75, 'state': 'OR', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 313, 'year': 2022, 'rate': 6.75, 'state': 'OR', 'incomeMin': 3650, 'filing_status': 's'},
	{'id': 315, 'year': 2022, 'rate': 8.75, 'state': 'OR', 'incomeMin': 50000, 'filing_status': 's'},
	{'id': 317, 'year': 2022, 'rate': 9.9, 'state': 'OR', 'incomeMin': 125000, 'filing_status': 's'},
	{'id': 320, 'year': 2022, 'rate': 3.07, 'state': 'PA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 319, 'year': 2022, 'rate': 3.07, 'state': 'PA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 322, 'year': 2022, 'rate': 3.75, 'state': 'RI', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 324, 'year': 2022, 'rate': 4.75, 'state': 'RI', 'incomeMin': 68200, 'filing_status': 'm'},
	{'id': 326, 'year': 2022, 'rate': 5.99, 'state': 'RI', 'incomeMin': 155050, 'filing_status': 'm'},
	{'id': 321, 'year': 2022, 'rate': 3.75, 'state': 'RI', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 323, 'year': 2022, 'rate': 4.75, 'state': 'RI', 'incomeMin': 68200, 'filing_status': 's'},
	{'id': 325, 'year': 2022, 'rate': 5.99, 'state': 'RI', 'incomeMin': 155050, 'filing_status': 's'},
	{'id': 328, 'year': 2022, 'rate': 0.0, 'state': 'SC', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 330, 'year': 2022, 'rate': 3.0, 'state': 'SC', 'incomeMin': 3200, 'filing_status': 'm'},
	{'id': 332, 'year': 2022, 'rate': 4.0, 'state': 'SC', 'incomeMin': 6410, 'filing_status': 'm'},
	{'id': 334, 'year': 2022, 'rate': 5.0, 'state': 'SC', 'incomeMin': 9620, 'filing_status': 'm'},
	{'id': 336, 'year': 2022, 'rate': 6.0, 'state': 'SC', 'incomeMin': 12820, 'filing_status': 'm'},
	{'id': 338, 'year': 2022, 'rate': 7.0, 'state': 'SC', 'incomeMin': 16040, 'filing_status': 'm'},
	{'id': 327, 'year': 2022, 'rate': 0.0, 'state': 'SC', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 329, 'year': 2022, 'rate': 3.0, 'state': 'SC', 'incomeMin': 3200, 'filing_status': 's'},
	{'id': 331, 'year': 2022, 'rate': 4.0, 'state': 'SC', 'incomeMin': 6410, 'filing_status': 's'},
	{'id': 333, 'year': 2022, 'rate': 5.0, 'state': 'SC', 'incomeMin': 9620, 'filing_status': 's'},
	{'id': 335, 'year': 2022, 'rate': 6.0, 'state': 'SC', 'incomeMin': 12820, 'filing_status': 's'},
	{'id': 337, 'year': 2022, 'rate': 7.0, 'state': 'SC', 'incomeMin': 16040, 'filing_status': 's'},
	{'id': 340, 'year': 2022, 'rate': 0.0, 'state': 'SD', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 339, 'year': 2022, 'rate': 0.0, 'state': 'SD', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 342, 'year': 2022, 'rate': 0.0, 'state': 'TN', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 341, 'year': 2022, 'rate': 0.0, 'state': 'TN', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 344, 'year': 2022, 'rate': 0.0, 'state': 'TX', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 343, 'year': 2022, 'rate': 0.0, 'state': 'TX', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 346, 'year': 2022, 'rate': 4.95, 'state': 'UT', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 345, 'year': 2022, 'rate': 4.95, 'state': 'UT', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 356, 'year': 2022, 'rate': 2.0, 'state': 'VA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 358, 'year': 2022, 'rate': 3.0, 'state': 'VA', 'incomeMin': 3000, 'filing_status': 'm'},
	{'id': 360, 'year': 2022, 'rate': 5.0, 'state': 'VA', 'incomeMin': 5000, 'filing_status': 'm'},
	{'id': 362, 'year': 2022, 'rate': 5.75, 'state': 'VA', 'incomeMin': 17000, 'filing_status': 'm'},
	{'id': 355, 'year': 2022, 'rate': 2.0, 'state': 'VA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 357, 'year': 2022, 'rate': 3.0, 'state': 'VA', 'incomeMin': 3000, 'filing_status': 's'},
	{'id': 359, 'year': 2022, 'rate': 5.0, 'state': 'VA', 'incomeMin': 5000, 'filing_status': 's'},
	{'id': 361, 'year': 2022, 'rate': 5.75, 'state': 'VA', 'incomeMin': 17000, 'filing_status': 's'},
	{'id': 348, 'year': 2022, 'rate': 3.35, 'state': 'VT', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 350, 'year': 2022, 'rate': 6.6, 'state': 'VT', 'incomeMin': 68400, 'filing_status': 'm'},
	{'id': 352, 'year': 2022, 'rate': 7.6, 'state': 'VT', 'incomeMin': 165350, 'filing_status': 'm'},
	{'id': 354, 'year': 2022, 'rate': 8.75, 'state': 'VT', 'incomeMin': 251950, 'filing_status': 'm'},
	{'id': 347, 'year': 2022, 'rate': 3.35, 'state': 'VT', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 349, 'year': 2022, 'rate': 6.6, 'state': 'VT', 'incomeMin': 40950, 'filing_status': 's'},
	{'id': 351, 'year': 2022, 'rate': 7.6, 'state': 'VT', 'incomeMin': 99200, 'filing_status': 's'},
	{'id': 353, 'year': 2022, 'rate': 8.75, 'state': 'VT', 'incomeMin': 206950, 'filing_status': 's'},
	{'id': 364, 'year': 2022, 'rate': 7.0, 'state': 'WA', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 363, 'year': 2022, 'rate': 7.0, 'state': 'WA', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 376, 'year': 2022, 'rate': 3.54, 'state': 'WI', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 378, 'year': 2022, 'rate': 4.65, 'state': 'WI', 'incomeMin': 17010, 'filing_status': 'm'},
	{'id': 380, 'year': 2022, 'rate': 5.3, 'state': 'WI', 'incomeMin': 34030, 'filing_status': 'm'},
	{'id': 382, 'year': 2022, 'rate': 7.65, 'state': 'WI', 'incomeMin': 374600, 'filing_status': 'm'},
	{'id': 375, 'year': 2022, 'rate': 3.54, 'state': 'WI', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 377, 'year': 2022, 'rate': 4.65, 'state': 'WI', 'incomeMin': 12760, 'filing_status': 's'},
	{'id': 379, 'year': 2022, 'rate': 5.3, 'state': 'WI', 'incomeMin': 25520, 'filing_status': 's'},
	{'id': 381, 'year': 2022, 'rate': 7.65, 'state': 'WI', 'incomeMin': 280950, 'filing_status': 's'},
	{'id': 366, 'year': 2022, 'rate': 3.0, 'state': 'WV', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 368, 'year': 2022, 'rate': 4.0, 'state': 'WV', 'incomeMin': 10000, 'filing_status': 'm'},
	{'id': 370, 'year': 2022, 'rate': 4.5, 'state': 'WV', 'incomeMin': 25000, 'filing_status': 'm'},
	{'id': 372, 'year': 2022, 'rate': 6.0, 'state': 'WV', 'incomeMin': 40000, 'filing_status': 'm'},
	{'id': 374, 'year': 2022, 'rate': 6.5, 'state': 'WV', 'incomeMin': 60000, 'filing_status': 'm'},
	{'id': 365, 'year': 2022, 'rate': 3.0, 'state': 'WV', 'incomeMin': 0, 'filing_status': 's'},
	{'id': 367, 'year': 2022, 'rate': 4.0, 'state': 'WV', 'incomeMin': 10000, 'filing_status': 's'},
	{'id': 369, 'year': 2022, 'rate': 4.5, 'state': 'WV', 'incomeMin': 25000, 'filing_status': 's'},
	{'id': 371, 'year': 2022, 'rate': 6.0, 'state': 'WV', 'incomeMin': 40000, 'filing_status': 's'},
	{'id': 373, 'year': 2022, 'rate': 6.5, 'state': 'WV', 'incomeMin': 60000, 'filing_status': 's'},
	{'id': 384, 'year': 2022, 'rate': 0.0, 'state': 'WY', 'incomeMin': 0, 'filing_status': 'm'},
	{'id': 383, 'year': 2022, 'rate': 0.0, 'state': 'WY', 'incomeMin': 0, 'filing_status': 's'}
];

const taxData = {
	federalTaxBracket,
	stateTaxBracket,
	ficaTaxRate
}

export default taxData;
